<template>
  <div>

    <v-dialog v-model="dialog" width="1200">
      <template v-slot:activator="{ on, attrs }"></template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-html="dialogTitle"></v-card-title>
        <v-card-text>
          <div v-html="dialogText" class="mb-4"></div>
          <pre style="width:100%; overflow-x: auto;" v-html="dialogCode"></pre>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">
        Crons manager
        <v-btn 
          @click.native="isOpenCreateCronDialog = !isOpenCreateCronDialog" 
          color="primary" 
          icon fab outlined elevation="4"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
        <!-- <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              v-bind="attrs" v-on="on"
              @click.native="isOpenCreateCronDialog = !isOpenCreateCronDialog" 
              color="primary" 
              icon fab outlined elevation="4"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
          </template>
          <span>Générer les fichiers pour un nouveau service / timer</span>
        </v-tooltip> -->
      </p>
      <v-spacer></v-spacer>
      <dev-menu></dev-menu>
    </div>

    <v-card class="container">
      <v-card-text>
        <div>
          <p>
            <pre>
              # COMMAND: systemctl --user list-timers
            </pre>
          </p>

          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="filteredData"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            single-select
            item-key="identifier"
            show-select
            class="elevation-1"
            disable-pagination
            :items-per-page="itemsPerPage"
          >
            <template v-slot:top>
              <div class="d-flex flex-row mb-6">
                <v-text-field
                  v-model="searchStr"
                  append-icon="mdi-magnify"
                  label="Search"
                  @input="search"
                  single-line
                  hide-details
                  class="ma-2"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn :disabled="!selected.length" v-on:click="filesRemote" color="primary"  elevation="4" class="ma-2">
                  <v-icon>
                    {{ icons.mdiTextBoxMultipleOutline }} 
                  </v-icon>
                  <div class="mx-2">Files</div>
                </v-btn>
                <v-btn :disabled="!selected.length" v-on:click="statusRemote" color="primary" elevation="4" class="ma-2">
                  <v-icon>
                    {{ icons.mdiStateMachine }}
                  </v-icon>
                  <div class="mx-2">Status</div>
                </v-btn>
                <v-btn :disabled="!selected.length" v-on:click="removeRemote" color="primary"  elevation="4" class="ma-2">
                  <v-icon>
                    {{ icons.mdiDelete }}
                  </v-icon>
                  <div class="mx-2">How to remove</div>
                </v-btn>   
              </div>          
            </template>
          </v-data-table>


          <!-- v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Identifier</th>
                  <th class="text-left">Last</th>
                  <th class="text-left">Passed</th>
                  <th class="text-left">Next</th>
                  <th class="text-left">Left</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in remoteList" :key="item.identifier">
                  <td><b class="primary--text">{{ item.identifier }}</b></td>
                  <td>{{ item.last }}</td>
                  <td>{{ item.passed }} ago</td>
                  <td>{{ item.next }}</td>
                  <td>{{ item.left }} left</td>
                  <td>
                    <v-btn :data-identifier="item.identifier" v-on:click="filesRemote" color="primary" icon fab elevation="4" class="mx-2">
                      <v-icon>{{ icons.mdiTextBoxMultipleOutline }}</v-icon>
                    </v-btn>
                    <v-btn :data-identifier="item.identifier" v-on:click="statusRemote" color="primary" icon fab elevation="4" class="mx-2">
                      <v-icon>{{ icons.mdiStateMachine }}</v-icon>
                    </v-btn>
                    <v-btn :data-identifier="item.identifier" v-on:click="removeRemote" color="error" icon fab elevation="4" class="mx-2">
                      <v-icon>{{ icons.mdiDelete }}</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table -->
          <p class="mt-6">
            <pre>
              # COMMANDS for uvicorn service:
              #    systemctl --user status api.rmr.reworldmediafactory.com.uvicorn.service              # show service status
              #    journalctl --user -fu api.rmr.reworldmediafactory.com.uvicorn.service                # show service logs
              #    cat /home/reportapi/.local/share/systemd/user/api.rmr.reworldmediafactory.com.uvicorn.service
              #    systemctl --user start api.rmr.reworldmediafactory.com.uvicorn.service               # Start service manually
              #    systemctl --user disable api.rmr.reworldmediafactory.com.uvicorn.service             # Disable service
              #    systemctl --user stop api.rmr.reworldmediafactory.com.uvicorn.service                # Stop service
            </pre>
          </p>
        </div>     
      </v-card-text>
    </v-card>
    <v-dialog v-model="isOpenCreateCronDialog" width="50vw">
      <create-cron-modal
        @openDialogFunction="ocCreateCronDialog"
        :openDialogIsOpen="isOpenCreateCronDialog"
      ></create-cron-modal>
    </v-dialog>
  </div>
</template>

<script>
import { mdiDelete, mdiPlus, mdiMagnify, mdiStateMachine, mdiTextBoxMultipleOutline } from '@mdi/js';
import CreateCronModal from "@/components/common/modals/CreateCronModal";
import DevMenu from "@/views/menus/DevMenu.vue";
import axios from "@axios";

export default {
  name: "CronCreate",
  components: {
    CreateCronModal,
    DevMenu,
  },
  data() {
    return {
      headers:[
        {
          text: 'Identifier',
          align: 'start',
          sortable: true,
          value: 'identifier',
        },
        {
          text: 'Last',
          align: 'start',
          sortable: true,
          value: 'last',
        },
        {
          text: 'Passed',
          align: 'start',
          sortable: true,
          value: 'passed',
        },
        {
          text: 'Next',
          align: 'start',
          sortable: true,
          value: 'next',
        },
        {
          text: 'Left',
          align: 'start',
          sortable: true,
          value: 'left',
        },
      ],
      searchStr: "",
      selected:[],
      page: 1,
      pageCount: 0,
      itemsPerPage: -1,
      sortBy: 'identifier',
      sortDesc: false,
      dialog: false,
      dialogTitle: "",
      dialogText: "",
      dialogCode: "",
      isOpenCreateCronDialog: false,
      // dialogCreate: false,
      identifier: false,
      remoteIdentifiersList: [],
      icons: {
        mdiStateMachine,
        mdiPlus,
        mdiDelete,
        mdiTextBoxMultipleOutline,
        mdiMagnify,
      },
      filteredData:[],
      remoteList: [
        /*
        {
          "next": "Fri 2022-07-29 03:26:34",
          "left": "16h",
          "last": "Thu 2022-07-28 03:06:59",
          "passed": "7h",
          "timer_unit": "precompute_db_data.computed.timer",
          "activates_service": "precompute_db_data.computed.service"
        }
        */
      ],
    }
  },
  computed: {
    myComputeFunction() {
      console.log("myComputeFunction");
    },
  },
  methods: {
    ocCreateCronDialog(value, response = false) {
      console.log(`ocCreateCronDialog(${value})`);
      this.isOpenCreateCronDialog = value;
      console.log(response)
      if(response){
        if(response.status == 201){
          let generatedStr = (response.data.written) ? "generated" : ""
          this.dialogTitle = `Service/timer ${generatedStr} files content`
          if(response.data.written){
            this.dialogText = `Check your repository. Files should be there.`
          }
          this.dialogCode = response.data.output;
          this.dialog = true;
        }
        else{
          console.log("ERROR")
        }
      }
    },
    async getRemote() {
      console.log("getRemote");
      let queryParams = {};
      // queryParams["limit"] = itemsPerPage;
      const response = await axios.get("/crons/remote");
      console.log("response", response)
      this.remoteIdentifiersList = response.data.identifiers;
      this.remoteList = response.data.list_timers;
      console.log("this.remoteList", this.remoteList);
      this.filteredData = this.remoteList;
    },
    async statusRemote(e) {
      // this.identifier = e.currentTarget.getAttribute("data-identifier");
      this.identifier = this.selected[0].identifier;
      console.log("removeRemote() this.identifier", this.identifier);
      // queryParams["limit"] = itemsPerPage;
      const response = await axios.get(`/crons/remote/status/${this.identifier }`);

      console.log("response", response)
      this.dialogTitle = `Status of ${this.identifier } service/timer`
      this.dialogCode = response.data.output;
      this.dialog = true;
      console.log("this.remoteList", this.remoteList);
    },
    async filesRemote(e) {
      // this.identifier = e.currentTarget.getAttribute("data-identifier");
      this.identifier = this.selected[0].identifier;
      console.log("removeRemote() this.identifier", this.identifier);
      const response = await axios.get(`/crons/remote/files/${this.identifier }`);

      console.log("response", response)
      this.dialogTitle = `Files of ${this.identifier } service/timer`
      this.dialogCode = response.data.output;
      this.dialog = true;
      console.log("this.remoteList", this.remoteList);
    },
    removeRemote(e) {
      // this.identifier = e.currentTarget.getAttribute("data-identifier");
      this.identifier = this.selected[0].identifier;
      console.log("removeRemote() this.identifier", this.identifier);
      this.dialogTitle = `To remove ${this.identifier} service/timer`
      this.dialogCode =
        `systemctl --user disable ${this.identifier}.report.timer       # Disable timer` + "\n" +
        `systemctl --user stop ${this.identifier}.report.timer          # Stop timer` + "\n" +
        `systemctl --user disable ${this.identifier}.report.service     # Disable service` + "\n" +
        `systemctl --user stop ${this.identifier}.report.service        # Stop service` + "\n" +
        `rm /home/reportapi/.local/share/systemd/user/${this.identifier}.report.timer` + "\n" +
        `rm /home/reportapi/.local/share/systemd/user/${this.identifier}.report.service`;
      this.dialog = true;
    },
    create() {
      console.log("create()");
    },
    getAll() {
      console.log("getAll()");
      this.getRemote()
    },
    search() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      setTimeout(() => {
        this.setFilteredData();
      }, 500);
    },
    setFilteredData() {
      let filteredData = [];
      let searchStrLC = this.searchStr.toLowerCase();
      for (let i = 0; i < this.remoteList.length; i++) {
        if (
          this.searchStr == "" ||
          this.remoteList[i]["identifier"].toLowerCase().includes(searchStrLC)
        ) {
          filteredData.push(this.remoteList[i]);
        }
      }
      this.filteredData = filteredData;
    },
  },
  mounted() {
    this.getAll();
  },
  watch: {

    // isOpenCreateCronDialog(visible) {
    //   if (visible) {
    //     this.identifier = "";
    //     this.$refs.FormCron.resetValidation();
    //   }
    // },
  },
}
</script>
