<template>
  <div>
    <v-card class="pa-4">
      <p class="text-2xl ma-5">Créer un nouveau service/timer</p>
      <p class="mx-6 mb-5">
        <a href="https://wiki.archlinux.org/title/Systemd_(Fran%C3%A7ais)/Timers_(Fran%C3%A7ais)" 
          title="Aide" alt="Aide"
          target="_blank"
        >Consulter l'aide: Systemd / Timers en français</a> : 
      </p>
      <v-row class="mx-1 my-3">
        <v-card-text>
          <v-form ref="FormCron">
            
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="identifier"
                  label="Identifier"
                  outlined
                  dense 
                  :rules="[required]"
                ></v-text-field>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: adn_get_and_store_posts_ga.weekly
                </p>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="description"
                  label="Description"
                  outlined
                  dense 
                  :rules="[required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="service_exec_start"
                  label="Service - Exec start"
                  outlined
                  dense
                  :rules="[required]"
                ></v-text-field>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: python -m crawlers adn_get_and_store_posts_ga --timespan=last_week
                </p>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="timer_on_calendar"
                  label="Timer - On calendar"
                  outlined
                  dense 
                  :rules="[required]"
                ></v-text-field>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Format: DayOfWeek Year-Month-Day Hour:Minute:Second<br/>
                  Examples: *-*-* 08:00:00 / Mon 22:30 / Sat *-*-1..7 18:00:00 (à 18h le 1er samedi de chaque mois)
                </p>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="timer_randomized_delay_sec"
                  label="Timer - Randomized delay sec"
                  outlined
                  dense 
                  :rules="[required]"
                ></v-text-field>
                <p class="mt-n4 font-weight-thin text-lg-right">
                  Example: 1h
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="saveCron"
                  >Générer les fichiers</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  @click.native="$emit('openDialogFunction', false)"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import { required } from "@core/utils/validation";

export default {
  name: "CreateCron",
  props: {
    openDialogIsOpen: {
      type: Boolean,
      required: true,
    },
  },
  created() {},
  methods: {
    async saveCron() {
      const isFormValid = this.$refs.FormCron.validate();
      if (!isFormValid) return;
      const response = await axios.post("/crons/create", {
        identifier: this.identifier,
        description: this.description,
        service_exec_start: this.service_exec_start,
        timer_on_calendar: this.timer_on_calendar,
        timer_randomized_delay_sec: this.timer_randomized_delay_sec
      });
      if (response.status === 201) {
        console.log("Service/Timer files generated. Check your local repository.")
        this.$emit("openDialogFunction", false, response);
      }
    },
  },
  data() {
    return {
      required,
      identifier: "service_timer_identifier",
      description: "Nom du département - Description du script automatique",
      service_exec_start: "python -m crawlers my_script --my-param=my-value",
      timer_on_calendar: "*-*-* 03:00:00",
      timer_randomized_delay_sec: "1h",
    };
  },
  watch: {
    openDialogIsOpen(visible) {
      if (visible) {
        this.identifier = "service_timer_identifier";
        this.description = "Nom du département - Description du script automatique";
        this.timer_on_calendar = "*-*-* 08:00:00";
        this.service_exec_start = "python -m crawlers my_script --my-param=my-value";
        this.timer_randomized_delay_sec = "1h";
        this.$refs.FormCron.resetValidation();
      }
    },
  },
  computed: {},
};
</script>
<!-- 
  <v-dialog v-model="isOpenCreateCronDialog" width="70vw">
    <create-cron-modal
      @openDialogFunction="ocCreateCronDialog"
      :openDialogIsOpen="isOpenCreateCronDialog"
    ></create-cron-modal>
  </v-dialog>
-->
